body {
  min-width: 1200px;
  margin: 0 !important;
}

/* antd 菜单样式修改 */

li.ant-menu-item-selected {
  background-color: transparent !important;
}

.ant-menu-dark li[role='menuitem'].ant-menu-item-selected span.ant-menu-title-content a {
  color: rgba(255, 255, 255, 0.65);
}

li.ant-menu-submenu-selected {
  background-color: transparent !important;
}
/* 
.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  white-space: normal;
  word-break: break-all;
} */
